import React, { Fragment, useEffect, useState } from "react";
import { useGetSingleGallery } from "../controllers/get_controller";
import { Link, useParams } from "react-router-dom";
import HeaderFooter from "generalComponents/HeaderFooter";
import { format } from "date-fns";
import Loader from "generalComponents/Loader";
import ModalManagement from "generalComponents/CustomModal";
import CustomPagination from "generalComponents/CustomPagination";

const SingleGallery = () => {
  const params = useParams();
  const [page, setPage] = useState(1);
  const pageSize = 50;
  const [selecetedImage, setSelectedImage] = useState();
  const onSelectImage = (data) => {
    setSelectedImage(data);
  };
  const onCloseVideoDetails = () => {
    setSelectedImage(undefined);
  };
  const { data, isLoading } = useGetSingleGallery(params?.id, {
    page,
    pageSize,
  });

  useEffect(() => {
    if (selecetedImage !== undefined) {
      window["image-viewer"]?.showModal();
    } else {
      window["image-viewer"]?.close();
    }
  }, [selecetedImage]);

  if (isLoading) return <Loader />;
  return (
    <HeaderFooter>
      <div className="pt-[184px] pb-20 max-w-section mx-auto px-5">
        <Link
          to="/gallery"
          className="text-sm text-[#667185] flex items-center gap-3 mb-5"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="3.5"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="3.5"
              stroke="#E4E7EC"
            />
            <path
              d="M7.14645 12.3536C6.95118 12.1583 6.95118 11.8417 7.14645 11.6464L9.14645 9.64645C9.34171 9.45118 9.65829 9.45118 9.85355 9.64645C10.0488 9.84171 10.0488 10.1583 9.85355 10.3536L8.70711 11.5L16.5 11.5C16.7761 11.5 17 11.7239 17 12C17 12.2761 16.7761 12.5 16.5 12.5L8.70711 12.5L9.85355 13.6464C10.0488 13.8417 10.0488 14.1583 9.85355 14.3536C9.65829 14.5488 9.34171 14.5488 9.14645 14.3536L7.14645 12.3536Z"
              fill="black"
            />
          </svg>

          <span>Go back</span>
        </Link>

        <div className="mb-3">
          <h1 className="font-semibold text-[52px] lg:text-[60px] lg:leading-[70px] text-purpleOverlay">
            {data?.data?.title}
          </h1>

          <p className="text-textGrey">
            {data?.data?.createdAt ? format(data?.data?.createdAt, "PPP") : ""}
          </p>
        </div>

        <div className="flex flex-wrap gap-2.5">
          {data?.data?.map((item, index) => (
            <Fragment key={index}>
              <img
                onClick={() => onSelectImage(item?.image?.url)}
                src={item?.image?.url}
                alt=""
                className="max-h-[200px] object-contain cursor-pointer"
                loading="lazy"
                decoding="async"
                color="lightgrey"
              />
            </Fragment>
          ))}
        </div>

        <div className="flex justify-end mt-10 min-w-full">
          <CustomPagination
            count={data?.pagination?.total}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
          />
        </div>
      </div>

      <ModalManagement
        id={"image-viewer"}
        onClose={onCloseVideoDetails}
        className={"w-full max-w-[100svh] max-h-[100svh]"}
      >
        <img src={selecetedImage} alt="" className="h-full object-contain" />
      </ModalManagement>
    </HeaderFooter>
  );
};

export default SingleGallery;
