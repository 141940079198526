import { useGetSermonById } from "modules/pageSermons/controllers/get_sermons_contoller";
import React, { useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loader from "./Loader";
import useWindowInactivity from "@toluade/use-window-inactivity";
// import { cozaLogo } from "assets/icons";
import HeaderFooter from "./HeaderFooter";

const VideoPlayer = ({ id }) => {
  const location = useLocation();
  const historyExists =
    location.key !== "default" && location.state !== "login";
  const videoRef = useRef();
  const params = useParams();
  const { data, isLoading, isFetched } = useGetSermonById(params?.id);
  //   console.log({ data });
  const inactive = useWindowInactivity(5);

  useEffect(() => {
    if (isFetched && !inactive) {
      videoRef.current?.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderFooter solid>
      <div className="relative pt-[98px]">
        <div className="fixed left-0 top-0 w-full h-[50%] bg-gradient-to-br from-purpleBg/30 to-white fade-b-20"></div>
        <div className="fixed left-0 top-[50%] w-full h-[50%] bg-gradient-to-br to-orangeBg/30 from-[#FFFFFF] fade-t-20"></div>
        {isLoading ? (
          <Loader ball />
        ) : (
          <div className="relative z-[2]">
            <div className="relative w-full">
              <Link
                to={historyExists ? -1 : "/sermon"}
                className="text-xs text-[#2F0640] flex items-center gap-3 p-5 w-fit"
              >
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="rotate-180"
                >
                  <path
                    d="M1 1L6.81206 6.81206C7.46814 7.46814 7.46814 8.53186 6.81206 9.18794L1 15"
                    stroke="#2F0640"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <span>{historyExists ? "Go back" : "Go to Sermons"}</span>
              </Link>

              <p className="text-lg font-medium text-purpleOverlay absolute inset-0 m-auto w-fit h-fit">
                Sermon Details
              </p>
            </div>

            <video
              ref={videoRef}
              controls
              className="w-full h-auto max-h-[calc(100svh-156px)]"
              poster={data?.data?.cover_image?.url}
            >
              <source src={data?.data?.content} type="video/mp4" />
            </video>

            <div className="px-5 py-10">
              <p className="text-xl font-semibold text-purpleOverlay">
                {data?.data?.title}
              </p>
              <p className="text-orangeBg">{data?.data?.facilitator}</p>

              <div>
                <p className="font-semibold text-purpleOverlay text-lg mt-5">
                  Description
                </p>
                <p className="text-sm text-purpleOverlay whitespace-pre-line">
                  {data?.data?.description}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </HeaderFooter>
  );
};

export default VideoPlayer;
