import React from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const StatusButton = ({ status, onClick }) => {
  let styles = {};

  switch (status.toLowerCase()) {
    case "pending":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "Pending",
      };
      break;
    case "under_review":
      styles = {
        backgroundColor: "#FFF3CD",
        color: "#856404",
        text: "Under Review",
      };
      break;
    case "approved":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Approved",
      };
      break;
    case "open":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Open",
      };
      break;
    case "accepted":
      styles = {
        backgroundColor: "#E7F6EC",
        color: "#036B26",
        text: "Accepted",
      };
      break;
    case "cancelled":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Cancelled",
      };
      break;
    case "declined":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Declined",
      };
      break;
    case "unapproved":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Unapproved",
      };
      break;
    case "delete":
      styles = {
        backgroundColor: "#F8D7DA",
        color: "#721C24",
        text: "Delete",
      };
      break;
    default:
      styles = {
        backgroundColor: "#E2E3E5",
        color: "#383D41",
        text: capitalizeFirstLetter(status),
      };
      break;
  }

  return (
    <button
      onClick={onClick}
      className="text-14px rounded-[20px] px-3 py-1"
      style={{ backgroundColor: styles.backgroundColor, color: styles.color }}
    >
      {styles.text}
    </button>
  );
};

export default StatusButton;
