import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./modules/dashboard/view/Dashboard";
import UserDetailsPage from "./modules/admin/userManagement/view/UserDetailsPage";
import AdminDashboard from "./modules/admin/AdminDashboard";
import AdminLoginPage from "./modules/authentication/view/AdminLoginPage";
import AdminLoginVerification from "./modules/authentication/view/AdminLoginVerification";

import TicketsPage from "./modules/admin/tickets/views/TicketsPage";
import BroadcastPage from "./modules/admin/broadcast/views/BroadcastPage";
import AccountVerification from "./modules/authentication/view/AccountVerificationPage";
import CreateAccountPage from "./modules/authentication/view/CreateAccountPage";
import PrivacyPolicyPage from "./modules/dashboard/view/PrivacyPolicyPage";
import TermsAndConditionsPage from "./modules/dashboard/view/TermsAndConditionsPage";
import SupportPage from "./modules/dashboard/view/SupportPage";
import FAQPage from "./modules/dashboard/view/FAQPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling
import HomePage from "./modules/homePage/view/HomePage";
import UserManagementPage from "./modules/admin/userManagement/view/UserManagementPage";
import LoginPage from "./modules/authentication/view/LoginPage";
import ForgotPasswordPage from "./modules/authentication/view/ForgotPasswordPage";
import ResetPasswordPage from "./modules/authentication/view/ResetPasswordPage";
import AdminAllCampusesPage from "./modules/admin/campus/view/AdminAllCampusesPage";
import PaymentLinksPage from "./modules/admin/paymentLinks/view/PaymentLinksPage";
import CategoriesPage from "./modules/admin/categories/view/CategoriesPage";
import AdminSermonPage from "./modules/admin/sermons/view/AdminSermonPage";
import AdminChildrenPage from "./modules/admin/children/view/AdminChildrenPage";
import AdminLibraryPage from "./modules/admin/library/view/AdminLibraryPage";
import AdminPraiseReportPage from "./modules/admin/praiseReport/view/AdminPraiseReportPage";
import AllAdminAppointmentsPage from "./modules/admin/appointments/view/AllAdminAppointmentsPage";
import GalleryListPage from "./modules/admin/gallery/view/GalleryListPage";
import GalleryImagePage from "./modules/admin/gallery/view/GalleryImagePage";
import AdminEventPage from "./modules/admin/events/view/AdminEventPage";
import AllCellsPage from "./modules/admin/cell/views/AllCellsPage";
import CellDetailsPage from "./modules/admin/cell/views/CellDetailsPage";
import FormTypesPage from "./modules/admin/familyProcesses/views/FormTypesPage";
import NotFound from "./generalComponents/NotFound";
import MainStartPage from "./modules/liveStream/videoSdkFiles/src/MainStartPage";
import Unauthorized from "./generalComponents/Unauthorized";
import AboutUs from "modules/pageAbout/view";
import Churches from "modules/pageChurches/view";
import CozaGive from "modules/pageCozaGive/view";
import Sermons from "modules/pageSermons/view";
import Library from "modules/pageLibrary/view";
import Store from "modules/pageStore/view";
import CozaKids from "modules/pageCozaKids/view";
import Devotional from "modules/pageDevotional/view";
import FamilyProcess from "modules/pageFamilyProcess/view";
import Events from "modules/pageEvents/view";
import Gallery from "modules/pageGallery/view";
import ScheduleAppointment from "modules/pageScheduleAppointment/view";
import PraiseReports from "modules/pagePraiseReports/view";
import SingleGallery from "modules/pageGallery/components/SingleGallery";
import AudioProvider from "modules/AudioProvider/view";
import VideoPlayer from "generalComponents/VideoPlayer";
import AudioPlayer from "generalComponents/AudioPlayer";
import ChurchGivings from "modules/pageCozaGive/components/ChurchGivings";
import Animation from "modules/pageAnimations/view";
import PaymentSuccesfulPage from "modules/pageStore/view/PaymentSuccesfulPage";
import SingleReportPage from "modules/pagePraiseReports/view/SingleReportPage";
import UserProfile from "modules/pageUserProfile/view";
import AdminTicketsDetailsPage from "modules/admin/tickets/views/TicketDetailPage";
import CozaTv from "modules/pageCozaTv/view";

function App() {
  return (
    <AudioProvider>
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/live-stream" element={<MainStartPage />} />

          <Route
            path="/admin/user-details/:userId"
            element={<UserDetailsPage />}
          />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route path="/admin/campuses" element={<AdminAllCampusesPage />} />
          <Route
            path="/admin/verification"
            element={<AdminLoginVerification />}
          />
          <Route
            path="/admin/user-management"
            element={<UserManagementPage />}
          />

          <Route
            path="/admin/praise-reports"
            element={<AdminPraiseReportPage />}
          />
          <Route path="/admin/payment-links" element={<PaymentLinksPage />} />
          <Route path="/admin/sermons" element={<AdminSermonPage />} />
          <Route path="/admin/children" element={<AdminChildrenPage />} />
          <Route path="/admin/library" element={<AdminLibraryPage />} />

          <Route path="/admin/tickets" element={<TicketsPage />} />
          <Route
            path="/admin/tickets/:ticketId"
            element={<AdminTicketsDetailsPage />}
          />
          <Route path="/admin/all-cells" element={<AllCellsPage />} />
          <Route
            path="/admin/cell-details/:cellId"
            element={<CellDetailsPage />}
          />

          <Route path="/admin/gallery" element={<GalleryListPage />} />
          <Route path="/admin/family-processes" element={<FormTypesPage />} />
          <Route path="*" element={<NotFound />} />

          <Route
            path="/admin/gallery-images/:galleryId"
            element={<GalleryImagePage />}
          />
          <Route path="/admin/events" element={<AdminEventPage />} />

          <Route
            path="/admin/appointments"
            element={<AllAdminAppointmentsPage />}
          />

          <Route path="/admin/broadcast" element={<BroadcastPage />} />
          <Route path="/admin/categories" element={<CategoriesPage />} />

          <Route path="/" element={<HomePage />} />
          <Route path="/coza-tv" element={<CozaTv />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/churches" element={<Churches />} />
          <Route path="/coza-give" element={<CozaGive />} />
          <Route path="/coza-give/church-givings" element={<ChurchGivings />} />
          <Route path="/sermon" element={<Sermons />} />
          <Route path="/sermon/video/:id" element={<VideoPlayer />} />
          <Route path="/sermon/audio/:id" element={<AudioPlayer />} />
          <Route path="/library" element={<Library />} />
          <Route path="/store" element={<Store />} />
          <Route
            path="/payment/confirmation"
            element={<PaymentSuccesfulPage />}
          />
          <Route path="/praise-reports" element={<PraiseReports />} />
          <Route path="/praise-reports/:id" element={<SingleReportPage />} />
          <Route path="/appointment" element={<ScheduleAppointment />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:id" element={<SingleGallery />} />
          <Route path="/family-process" element={<FamilyProcess />} />
          <Route path="/coza-kids" element={<CozaKids />} />
          <Route path="/coza-kids/devotional" element={<Devotional />} />
          <Route path="/coza-kids/animation" element={<Animation />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/account-verification"
            element={<AccountVerification />}
          />
          <Route path="/create-account" element={<CreateAccountPage />} />

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/terms-and-condition"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/support" element={<SupportPage />} />

          <Route path="/faq" element={<FAQPage />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </Router>
      <ToastContainer
        // toastClassName={
        //   "relative bg-brandPurple flex py-4 px-3 rounded-full text-center overflow-hidden cursor-pointer shadow-lg"
        // }
        // bodyClassName={"text-white text-base font-normal z-[9999]"}
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        icon={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
      />
    </AudioProvider>
  );
}

export default App;
