import { RoundedButton } from "generalComponents/Button";
import ModalManagement from "generalComponents/CustomModal";
import PlayVideo from "generalComponents/PlayVideo";
import SermonDisc from "generalComponents/SermonDisc";
import { useAudioContext } from "modules/AudioProvider/view";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

const SermonDetails = ({ id, title, message, type, onClose }) => {
  const { setCurrentAudio } = useAudioContext();
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const onCopy = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(data);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const audioLink = `${appBaseUrl}/sermon/audio/${message?.id}`;
  const videoLink = `${appBaseUrl}/sermon/video/${message?.id}`;

  const handleCopy = (e) => {
    if (type === "video") {
      onCopy(e, videoLink);
    } else if (type === "audio") {
      onCopy(e, audioLink);
    }
  };

  const goToVideoSermonDetails = () => navigate(`/sermon/video/${message?.id}`);
  // const goToAudioSermonDetails = () => navigate(`/sermon/audio/${message?.id}`);

  const handlePlay = () => {
    if (type === "video") {
      goToVideoSermonDetails();
      // window[`detail-${message?.id}`]?.showModal();
      // window[id]?.close();
    } else if (type === "audio") {
      setCurrentAudio(message);
      window[id]?.close();
    }
  };
  return (
    <ModalManagement id={id} title={title} onClose={onClose}>
      <div className="px-8">
        <div className="flex justify-center items-center h-fit mb-8 relative">
          {type === "audio" ? (
            <SermonDisc
              image={message?.cover_image?.url}
              className={"mb-2 border-purpleBg size-[200px]"}
              small={true}
            />
          ) : (
            <img
              src={message?.cover_image?.url}
              alt=""
              className="size-full h-[246px] rounded-[12px] object-cover"
            />
          )}
          {type === "video" ? (
            <button
              onClick={goToVideoSermonDetails}
              className="size-[65px] bg-purpleOverlay/50 absolute inset-0 m-auto rounded-full flex justify-center items-center"
            >
              <svg
                width="19"
                height="21"
                viewBox="0 0 19 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.598389 3.71242C0.598389 1.29048 3.23006 -0.2147 5.31762 1.01328L16.8575 7.8015C18.916 9.01231 18.916 11.989 16.8575 13.1998L5.31762 19.988C3.23006 21.216 0.598389 19.7108 0.598389 17.2889V3.71242Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : null}
        </div>
        <p className="text-xl font-semibold text-purpleOverlay">
          {message.title}
        </p>
        <p className="text-orangeBg">{message.facilitator}</p>
        <div className="mt-5">
          <h4 className="font-medium text-purpleOverlay">About</h4>
          <p className="text-xs text-textGrey whitespace-pre-line">
            {message?.description}
          </p>
        </div>
        <RoundedButton
          onClick={handlePlay}
          className="py-4 mt-20 w-full text-base flex justify-center items-center gap-2.5 rounded-[40px] font-semibold"
        >
          <svg
            width="19"
            height="21"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.598389 3.71242C0.598389 1.29048 3.23006 -0.2147 5.31762 1.01328L16.8575 7.8015C18.916 9.01231 18.916 11.989 16.8575 13.1998L5.31762 19.988C3.23006 21.216 0.598389 19.7108 0.598389 17.2889V3.71242Z"
              fill="white"
            />
          </svg>
          <span>Play {type === "audio" ? "Audio" : "Video"}</span>
        </RoundedButton>
        <RoundedButton
          onClick={handleCopy}
          className="py-4 mt-2 w-full text-base flex justify-center items-center gap-2.5 rounded-[40px] bg-lightPurpleBg text-purpleOverlay font-semibold"
        >
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5973 3.07479L14.8167 19.3682C14.6784 19.7729 14.4175 20.1237 14.0707 20.3716C13.7238 20.6194 13.3085 20.7517 12.883 20.7499C12.4625 20.7547 12.0508 20.6284 11.7045 20.3883C11.3582 20.1482 11.0944 19.8061 10.9494 19.4091L8.79181 13.7597L10.3591 12.1835L12.8932 9.63514C13.0848 9.44242 13.1925 9.18103 13.1925 8.90848C13.1925 8.63594 13.0848 8.37455 12.8932 8.18183C12.7016 7.98911 12.4416 7.88084 12.1706 7.88084C11.8996 7.88084 11.6397 7.98911 11.448 8.18183L8.91394 10.7302L7.30595 12.3166L1.69834 10.1366C1.27959 10.0098 0.912595 9.75065 0.65173 9.39765C0.390865 9.04464 0.25 8.61653 0.25 8.17671C0.25 7.7369 0.390865 7.30879 0.65173 6.95578C0.912595 6.60277 1.27959 6.34365 1.69834 6.2168L17.9411 0.403566C18.3125 0.250099 18.7208 0.210554 19.1145 0.289908C19.5083 0.369263 19.8699 0.563971 20.1538 0.849527C20.4378 1.13508 20.6314 1.49873 20.7103 1.89469C20.7892 2.29066 20.7499 2.70125 20.5973 3.07479Z"
              fill="#2F0640"
            />
          </svg>

          <span>{copied ? "Link copied" : "Share"}</span>
        </RoundedButton>
      </div>

      {type === "video" ? (
        <PlayVideo id={`detail-${message?.id}`} src={message?.content} />
      ) : null}
    </ModalManagement>
  );
};

export default SermonDetails;
