import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
import { useQuery } from "react-query";

export const useGetSermonById = (id) => {
  return useQuery(
    ["sermon_by_id", id],
    async () => {
      try {
        const [response] = [await AxiosWithToken.get(`/sermon/${id}`)];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

const useGetSermons = (params = {}) => {
  return useQuery(
    ["sermons", params],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/sermon/list`, { params }),
        ];
        //console.log(`this is the current subscription status ${response}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetSermons;
