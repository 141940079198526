import { useState, Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";

export default function ComboboxInput({
  name,
  value,
  setValue,
  label,
  labelClass,
  placeholder,
  className,
  options,
  required,
  containerClass,
}) {
  // const [selected, setSelected] = useState(people[0]);
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="flex flex-col mb-4">
      {label && (
        <label
          htmlFor="HeadlineAct"
          className={twMerge("text-sm font-medium mb-2", labelClass)}
        >
          {label}
        </label>
      )}
      <Combobox value={value} onChange={setValue}>
        <div className="relative mt-1">
          <div
            className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left focus:outline-none sm:text-sm border border-[#D0D5DD] focus-within:border-purpleBg duration-200"
            // className="w-full pe-10 text-gray-700 sm:text-sm [&::-webkit-calendar-picker-indicator]:opacity-0 bg-white rounded-md border border-[#D0D5DD] p-2 py-3 outline-none focus:outline-none focus:border-purpleBg duration-200"
          >
            <Combobox.Input
              placeholder={placeholder ?? ""}
              className="w-full border-none outline-none py-3 pl-3 pr-10 text-sm leading-5 focus:ring-0 bg-white text-gray-700"
              displayValue={(option) => option.label}
              onChange={(event) => setQuery(event.target.value)}
              required={required ?? false}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {options?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions?.map((option, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-teal-600 text-neutral-900"
                          : "text-neutral-700"
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {options.profile_picture && (
                            <div className="size-5 overflow-hidden rounded-full border-[1.7px] border-purpleBg">
                              <img
                                src={option?.profile_picture}
                                className="object-contain rounded-full w-full"
                                alt=""
                              />
                            </div>
                          )}{" "}
                          {option.label}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-neutral-900" : "text-neutral-700"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
