import React, { useEffect, useState } from "react";
import useGetSingleFormManager from "../controllers/getSingleFormController";
import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import FormDetailTile from "../../../../generalComponents/FormDetailTile";
import separateWords from "../wordSeparator";
import { toast } from "react-toastify";
import { formatDate } from "utils/formatDate";

const BabyChristineningDetailForm = ({ details }) => {
  const formData = [
    // { name: "Form Type", info: separateWords(formType) },
    {
      name: "Form Status",
      info: details?.status,
    },
    {
      name: "Parent Name",
      info: `${details?.user?.first_name} ${details?.user?.last_name}`,
    },
    {
      name: "Phone No.",
      info: details?.user?.phone,
    },
    {
      name: "Address",
      info: `${details?.user?.location?.address}, ${details?.user?.location?.city}, ${details?.user?.location?.state}, ${details?.user?.location?.country}`,
    },
    {
      name: "Marriage Date",
      info: details?.marriage_date,
    },
    {
      name: "Marriage Location",
      info: details?.marriage_location,
    },
    {
      name: "Membership Duration",
      info: details?.member_duration,
    },
    {
      name: "Child's Name",
      info: details?.child_name,
    },
    {
      name: "Child's Date of Birth",
      info: details?.child_dob,
    },
    {
      name: "Child's Gender",
      info: details?.child_gender,
    },
    {
      name: "Child's Position",
      info: details?.position_of_child,
    },
    {
      name: "Father's Signature",
      info: details?.father_signature,
    },
    {
      name: "Mother's Signature",
      info: details?.mother_signature,
    },
    {
      name: "Guarantor",
      info: details?.guarantor,
    },
    {
      name: "Partnership Status",
      info: details?.partnership_status,
    },
    {
      name: "Partnership Id",
      info: details?.partnership_id,
    },
    {
      name: "Submitted On",
      info: formatDate(details?.createdAt),
    },
    {
      name: "Updated On",
      info: formatDate(details?.updatedAt),
    },
  ];

  return (
    <div>
      <div class="flow-root mt-16 ">
        <dl class="-my-3 divide-y divide-gray-100 text-sm mb-20">
          {formData.map((details, index) => (
            <FormDetailTile details={details} index={index} />
          ))}
        </dl>
      </div>
    </div>
  );
};

export default BabyChristineningDetailForm;
