import AxiosWithToken from "constants/api_management/MyHttpHelperWithToken";
// import useUpdateManager from "constants/controller_templates/put_controller_template";
import showToast from "constants/showToast";
import { useMutation } from "react-query";

export const EditPraiseReportManager = (testimonyId) => {
  // const isMultiKey = false;
  const mutation = useMutation({
    mutationKey: ["praiseReports"],
    mutationFn: (body) => {
      return AxiosWithToken.put(`/testimony/${testimonyId}`, body);
    },
  });

  const editPraiseReport = async (details) => {
    console.log("got here");
    try {
      await mutation.mutateAsync(details);
      showToast("Praise report updated successfully", "success");
    } catch (error) {
      // Handle error if necessary
      showToast(error?.message, "error");
      throw error;
    }
  };
  return {
    editPraiseReport,
    ...mutation,
  };
};
