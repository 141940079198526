import ModalManagement from "generalComponents/ModalManagement";
import React from "react";

import DisplayFile from "generalComponents/DisplayFile";
// import CustomButton from "generalComponents/Button";
import { ApproveUnapprovePraiseReportManager } from "../controllers/approveUnapprovePraiseReportController";
import EditIcon from "assets/icons/EditIcon";
import { TrashIcon } from "@heroicons/react/outline";
import { Avatar } from "@mui/material";
import { Spinner } from "generalComponents/Loader";

const ViewPraiseReport = ({ details }) => {
  const { approveUnapprove, isLoading } = ApproveUnapprovePraiseReportManager();

  const action = (condition, isLoading) => {
    if (isLoading) {
      return <Spinner />;
    }

    if (condition) return "Unapprove";
    else return "Approve";
  };
  return (
    <ModalManagement id={"view_praiseReport"} title={"Praise Report"}>
      <div className="w-full md:w-[500px] flex flex-col gap-5 mt-5">
        <div className="flex items-center justify-end gap-3">
          <button
            onClick={() => {
              document.getElementById("view_praiseReport").close();
              document.getElementById("add_praiseReport").showModal();
            }}
            className="group flex items-center gap-1 py-2 px-3 duration-200 rounded-md shadow-md font-medium text-neutral-600 hover:text-neutral-800 hover:shadow-neutral-800/20"
          >
            <EditIcon className={"size-4"} />
            <span>Edit</span>
          </button>
          <button
            onClick={() => {
              document.getElementById("admin_delete").showModal();
            }}
            className="text-red-600 hover:text-red-700 font-medium flex items-center gap-1 py-2 px-3 duration-200 rounded-md hover:shadow-red-700/20 shadow-md"
          >
            <TrashIcon className="size-4" />
            <span>Delete</span>
          </button>
        </div>
        <DisplayFile
          fileUrl={
            details?.mediaUrl
              ? details?.mediaUrl
              : details?.imageUrl
              ? details?.imageUrl
              : details?.user?.profile_picture
          }
        />
        <div className="w-full flex flex-col">
          <p className="text-12px font-light text-textGrey">
            {" "}
            Category:{" "}
            <span className="text-brandPurple">{details?.category?.name}</span>
          </p>
          <p className="text-20px text-purpleOverlay font-semibold">
            {details?.title}
          </p>
        </div>
        <div className="flex items-start gap-3">
          <div className="size-10 shadow-md rounded-full overflow-hidden grid place-content-center">
            {details?.user?.profile_picture ? (
              <img
                src={details?.user?.profile_picture}
                alt=""
                className="object-contain w-full"
              />
            ) : (
              <Avatar />
            )}
          </div>
          <div className="flex flex-col w-full">
            <p className="text-[15px] text-purpleOverlay">
              {details?.user?.first_name} {details?.user?.last_name}
            </p>
            <p className="text-[15px] text-textGrey">{details?.user?.phone}</p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <p className="text-[15px] text-purpleOverlay">About</p>
          <p className="text-12px text-textGrey">{details?.description}</p>
        </div>

        <button
          onClick={async () => {
            const info = {
              id: details?.id,
              status: !details?.approved,
            };
            await approveUnapprove(info);
            document.getElementById("view_praiseReport").close();
          }}
          disabled={isLoading}
          className={`w-full rounded-lg py-3 px-3 font-semibold mt-10 mb-5 duration-200 text-white disabled:cursor-not-allowed disabled:opacity-70 grid place-content-center ${
            details?.approved
              ? "bg-red-600 hover:bg-red-700"
              : "bg-green-700 hover:bg-green-800"
          }`}
        >
          {action(details?.approved, isLoading)}
        </button>

        {/* <div className="flex flex-col w-full">
          <CustomButton
            buttonText={details?.approved ? "Unapprove" : "Approve"}
            isLoading={isLoading}
            onClick={async () => {
              const info = {
                id: details?.id,
                status: !details?.approved,
              };
              await approveUnapprove(info);
              document.getElementById("view_praiseReport").close();
            }}
            buttonColor={details?.approved ? "brandRed" : "success"}
            radius={"rounded-[40px]"}
          />
          <div className="flex items-center gap-5 w-full my-2">
            <CustomButton
              buttonText={"Edit"}
              buttonColor={"purpleOverlay"}
              className={"w-full"}
              radius={"rounded-[40px]"}
              onClick={() => {
                document.getElementById("view_praiseReport").close();
                document.getElementById("add_praiseReport").showModal();
              }}
            />
            <CustomButton
              buttonText={"Delete"}
              buttonColor={"brandRed"}
              onClick={() => {
                document.getElementById("admin_delete").showModal();
              }}
              className={"w-full"}
              radius={"rounded-[40px]"}
            />
          </div>
        </div> */}
      </div>
    </ModalManagement>
  );
};

export default ViewPraiseReport;
