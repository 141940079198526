import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useGetFormTypesManager from "../controllers/getFormTypesController";

import Loader from "../../../../generalComponents/Loader";
import ErrorManager from "../../../../generalComponents/ErrorManager";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import separateWords from "../wordSeparator";
import SearchComponent from "generalComponents/SearchComponent";
import InputWithFullBoarder from "generalComponents/InputWithFullBoarder";
import TabsManagement from "generalComponents/TabsManagement";
import useDebounce from "utils/UseDebounce";
import AvatarWithTitle from "generalComponents/AvatarWithTitle";
import { formatDate } from "utils/formatDate";
import StatusButton from "generalComponents/StatusButton";
import PaginationRounded from "generalComponents/Pagination";
import TablesComponent from "generalComponents/TablesComponent";
import CategoriesNav from "generalComponents/CategoriesNav";
import useGetFormsManager from "../controllers/getFormController";
import { twMerge } from "tailwind-merge";
import { camelCaseTransform } from "utils/camelCaseTransform";
import ViewFormDetails from "../components/ViewFormDetails";
import CategoryButton from "generalComponents/CategoryButton";
import AdminWrapper from "modules/admin/AdminWrapper";
import useGetCampusListManager from "modules/admin/campus/controllers/getCampusListController";
import DataTable from "generalComponents/DataTable";
import { validTableKeys } from "../utils";

const FormTypesPage = () => {
  const statuses = ["All", "pending", "accepted", "declined"];
  const { isLoading, data } = useGetFormTypesManager();
  const [selectedType, setSelectedType] = useState("");
  const [selected, setSelected] = useState("");
  const [status, setStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&first_name=${searchValue}`, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tableValues, setTableValues] = useState({
    pageIndex: 0,
    perPage: 20,
  });
  function setter(name, value) {
    setTableValues((val) => ({
      ...val,
      [name]: value,
    }));
  }
  const {
    data: campuses,
    isSuccess,
    isLoading: fetchingCampuses,
  } = useGetCampusListManager();

  // const tableHeads = [
  //   "Name",
  //   "Email",
  //   "Phone",
  //   "Campus",
  //   "Date",
  //   "Status",
  //   "Action",
  // ];
  // const getFormattedValue = (el, index) => {
  //   return [
  //     <AvatarWithTitle
  //       image={el.user?.profile_picture}
  //       title={`${el?.user?.first_name} ${el?.user?.last_name}`}
  //     />,
  //     el?.user?.email,
  //     el?.user?.phone,
  //     campuses?.data?.find((campus) => campus?.id === el?.campus)?.name,
  //     formatDate(el?.createdAt),
  //     <StatusButton
  //       status={el?.status === "requested" ? "pending" : el?.status}
  //     />,
  //   ];
  // };

  function modifyData(data) {
    if (!data.length) return [];
    const newArray = data?.map((item) => ({
      ...item,
      phone: item?.user?.phone,
      email: item?.user?.email,
      church: campuses?.data?.find((campus) => campus?.id === item?.campus)
        ?.name,
      status_badge: (
        <StatusButton
          status={item?.status === "requested" ? "pending" : item?.status}
        />
      ),
      date: formatDate(item?.createdAt),
      name: (
        <AvatarWithTitle
          image={item?.user?.profile_picture}
          title={`${item?.user?.first_name} ${item?.user?.last_name}`}
        />
      ),
    }));

    return newArray;
  }

  const { isLoading: loadingForms, data: forms } = useGetFormsManager({
    page: tableValues?.pageIndex + 1,
    pageSize: tableValues?.perPage,
    searchQuery: searchValue ? debouncedSearchValue : "",
    slug: selectedType,
    status: currentIndex === 0 ? "" : `&status=${statuses[currentIndex]}`,
    enabled: Boolean(selectedType),
  });

  function onRowClick(rowData) {
    setSelected(rowData);
    if (rowData?.id === selected?.id) {
      document.getElementById("view_formDetails")?.showModal();
    }
  }

  useEffect(() => {
    if (data?.data) {
      setSelectedType(`${data?.data[0].slug}`);
    }
  }, [data?.data]);

  useEffect(() => {
    if (selected) {
      document.getElementById("view_formDetails").showModal();
    }
  }, [selected]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AdminWrapper title={"Form Types"}>
      <div className="flex flex-col h-[90vh]">
        <div className="flex justify-between flex-col gap-5 md:flex-row w-full mb-10">
          <div className=" flex flex-col items-start">
            <p className="text-[24px] font-semibold">Family Process</p>
            <p className="text-14px">30 Family Process in total</p>
          </div>
        </div>
        <div className="flex items-center justify-between flex-col gap-5 md:flex-row">
          <div className="max-w-[291px]">
            <SearchComponent
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <InputWithFullBoarder type={"date"} />
        </div>

        <div className="flex gap-2 max-w-[1240px] overflow-auto scrollbar-hide min-h-[47px]">
          {data?.data.map((form, index) => (
            <CategoryButton
              key={index}
              group={form?.name}
              selected={selectedType === form?.slug}
              onClick={() => {
                setSelectedType(form?.slug);
              }}
            />
          ))}
        </div>

        <div className="w-full my-0">
          <TabsManagement
            options={statuses}
            currentView={currentIndex}
            setCurrentView={setCurrentIndex}
          />
        </div>

        {/* user tables */}
        <div className="md:h-[65vh] relative md:overflow-y-auto scrollbar-hide">
          {loadingForms ? (
            <Loader isSmall />
          ) : (
            <DataTable
              tableData={modifyData(forms?.data?.forms ?? [])}
              totalItemCount={forms?.data?.pagination?.total}
              validTableKeys={validTableKeys}
              onRowClick={onRowClick}
              noOfItemsPerPage={tableValues?.perPage}
              onCheckClick={() => null}
              onItemsPerPageChange={(newPage) => setter("perPage", newPage)}
              pageIndex={tableValues?.pageIndex}
              onPageIndexChange={(idx) => setter("pageIndex", idx)}
              showFooter
            />
          )}
          {/* <TablesComponent
            data={forms?.data?.forms}
            headers={tableHeads}
            isLoading={loadingForms}
            getFormattedValue={getFormattedValue}
            buttonFunction={(val) => {
              console.log(val);
              setSelected(val);
              if (val?.id === selected?.id) {
                document.getElementById("view_formDetails")?.showModal();
              }
              // navigate(
              //   `/admin/form-details/${camelCaseTransform(val.type)}/${
              //     val.type
              //   }/${val._id}`
              // );
            }}
          /> */}
        </div>

        {/* {forms?.data?.forms.length && (
          <div className="mb-10 flex items-center justify-center">
            <PaginationRounded
              count={data?.pagination?.pageTotal}
              defaultPage={data?.pagination?.currentPage}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )} */}
      </div>
      {selected && <ViewFormDetails details={selected} />}
    </AdminWrapper>
  );
};

export default FormTypesPage;
